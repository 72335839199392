// leaderboard.component.ts
import { Component, OnInit } from '@angular/core';

interface LeaderboardEntry {
  rank: number;
  name: string;
  score: number;
  date: string;
}

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent {
  weeklyLeaders: LeaderboardEntry[] = [
    { rank: 1, name: "Emma Simonian", score: 1590, date: "Oct 16, 2024" },
    { rank: 2, name: "James Lewis", score: 1560, date: "Oct 16, 2024" },
    { rank: 3, name: "Sarah Harris", score: 1550, date: "Oct 16, 2024" },
    { rank: 4, name: "Michael Kopernik", score: 1540, date: "Oct 13, 2024" },
    { rank: 5, name: "Lisa Wilson", score: 1530, date: "Oct 23, 2024" },
    { rank: 6, name: "David Kasparian", score: 1520, date: "Oct 23, 2024" },
    { rank: 7, name: "Anna Martinez", score: 1510, date: "Oct 16, 2024" },
    { rank: 8, name: "Laura Rondelli", score: 1500, date: "Oct 09, 2024" },
    { rank: 9, name: "Maria Scott", score: 1490, date: "Oct 02, 2024" },
    { rank: 10, name: "Robert Thompson", score: 1480, date: "Oct 02, 2024" }
  ];

  monthlyLeaders: LeaderboardEntry[] = [
    { rank: 1, name: "Thomas Hall", score: 1600, date: "Mar 5, 2024" },
    { rank: 2, name: "Emma Simonian", score: 1590, date: "Oct 16, 2024" },
    { rank: 3, name: "Sophie Loladze", score: 1590, date: "Mar 8, 2024" },
    { rank: 4, name: "Alex Green", score: 1570, date: "Feb 28, 2024" },
    { rank: 5, name: "Emily Davis", score: 1560, date: "Mar 1, 2024" },
    { rank: 6, name: "Davit Baramidze", score: 1550, date: "Mar 3, 2024" },
    { rank: 7, name: "Victoria Clark", score: 1540, date: "Feb 25, 2024" },
    { rank: 8, name: "Kevin Moore", score: 1530, date: "Mar 2, 2024" },
    { rank: 9, name: "Julia Nilson", score: 1520, date: "Feb 27, 2024" },
    { rank: 10, name: "Chris Baker", score: 1510, date: "Mar 4, 2024" },
  ];
}