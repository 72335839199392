<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                
                <h2>Participate In</h2>
                <h1>Weekly SAT Rally</h1>
                <p style="color: black;">Challange yourself with real-time exams in a true testing atmosphere.</p>
                <span (click)="toSignUp()" class="app-button">Register Now</span>
            </div>
        </div>
    </div>
</div>