import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ExamService {
  private apiUrl = environment.PROXY_URL + 'exams';
  constructor(private http: HttpClient) {}

  getExams(page: number, limit: number): Observable<any[]> {
    console.log("getExams apiUrl", this.apiUrl);
    return this.http.get<any[]>(`${this.apiUrl}?page=${page}&limit=${limit}`);
  }

  getUserExams(userId: string, page: number, limit: number): Observable<any[]> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
 
    return this.http.get<any[]>(`${environment.PROXY_URL}user/${userId}/exams`, { params }).pipe(
      tap(response => console.log('Response received:', response)),
      catchError(error => {
        console.error('Request failed:', error);
        throw error;
      })
    );
  }

  getUserExam(userId: string, examId: string): Observable<any[]> {
    return this.http.get<any>(`${environment.PROXY_URL}user/${userId}/exams/${examId}`).pipe(
      tap(response => console.log('Response received:', response)),
      catchError(error => {
        console.error('Request failed:', error);
        throw error;
      })
    );
  }

  getExamById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  getExamBlockById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/blocks/${id}`);
  }

  getUserExamBlock(blockId: string, userId: string): Observable<any> {
    return this.http.get(`${environment.PROXY_URL}user/${userId}/block/${blockId}`).pipe(
      tap(response => console.log('Response received:', response)),
      catchError(error => {
        throw error;  
      })
    );
  }

  registerUserExamBlock(examBlock: any) {
    console.log("sendFinishedExamBlock examBlock:", examBlock);
    return this.http
    .post <any>(environment.PROXY_URL + 'user/results/block', examBlock, {})
    .pipe(catchError(err => {
      return throwError(err);
    }));
  }

  registerUserExam(exam) {
     console.log("registerUserExam exam", exam);
     let user = JSON.parse(localStorage.getItem('user'));
     let userExam = {
       examId: exam['_id'],
       userId: user["id"]
     }
     console.log("userExam", userExam);
     this.http
      .post <any>(environment.PROXY_URL + 'user/results', userExam, {})
      .pipe(catchError(err => {
        return throwError(err);
      })).subscribe({
        next: (response) => {
          console.log('registerUserExam Saved Success:', response);
        },
        error: (error) => {
          console.error('registerUserExam Error:', error);
        }
      });
  }

  getFullUserExamData(examId: string, userId: string) {
    return this.getExamById(examId).pipe(
      switchMap(async exam => {
        console.log("examId", examId);
        console.log("inside getFullUserExamData exam", exam);
        if (!exam.exam_blocks?.length) {
          return { exam, examBlocks: [], userExamBlocks: [] };
        }
  
        const blockResults = [];
        const userBlockResults = [];
  
        for (const block of exam.exam_blocks) {
          if (block.hasQuestions) {

            const blockData = await this.getExamBlockById(block.id).toPromise();
            const userBlockData = await this.getUserExamBlock(block.id, userId).toPromise();
            
            blockResults.push(blockData);
            userBlockResults.push(userBlockData);
          }
        }

        return {
          exam,
          examBlocks: blockResults,
          userExamBlocks: userBlockResults
        };
      }),
      catchError(() => of({ exam: null, examBlocks: [], userExamBlocks: [] }))
    );
  }


  /// Static code below
  categories: any[] = [
    {
      id: 1,
      name: 'IT Certifications',
      description: 'Master professional IT certifications including CompTIA, Cisco, AWS, and Microsoft certifications',
      iconClass: 'fas fa-laptop-code',
      examCount: 45,
      bgClass: 'bg-primary-soft'
    },
    {
      id: 2,
      name: 'Academic Tests',
      description: 'Comprehensive prep for SAT, ACT, GRE, GMAT, and other standardized academic tests',
      iconClass: 'fas fa-graduation-cap',
      examCount: 32,
      bgClass: 'bg-success-soft'
    },
    {
      id: 3,
      name: 'Language Tests',
      description: 'Expert preparation for TOEFL, IELTS, DELF, and other language proficiency certifications',
      iconClass: 'fas fa-language',
      examCount: 28,
      bgClass: 'bg-info-soft'
    },
    {
      id: 4,
      name: 'Professional Licenses',
      description: 'Specialized exam prep for medical, legal, finance, and other professional licensing',
      iconClass: 'fas fa-id-card',
      examCount: 37,
      bgClass: 'bg-warning-soft'
    },
    {
      id: 5,
      name: 'Government Exams',
      description: 'Comprehensive preparation for civil service and government position examinations',
      iconClass: 'fas fa-landmark',
      examCount: 23,
      bgClass: 'bg-danger-soft'
    },
    {
      id: 6,
      name: 'Industry Certifications',
      description: 'Targeted prep for specialized industry certifications across various professional domains',
      iconClass: 'fas fa-certificate',
      examCount: 41,
      bgClass: 'bg-purple-soft'
    }
  ];

  private exams: any[] = [
    {
      id: 1,
      categoryId: 1,
      name: 'CompTIA A+ Certification',
      description: 'Complete preparation for CompTIA A+ certification covering hardware, operating systems, and mobile devices',
      duration: '120 mins',
      questionCount: 90,
      difficulty: 'Intermediate',
      price: 299,
      rating: 4.8,
      students: 12543
    },
    {
      id: 2,
      categoryId: 1,
      name: 'AWS Solutions Architect',
      description: 'Comprehensive exam prep for AWS Certified Solutions Architect - Associate certification',
      duration: '130 mins',
      questionCount: 65,
      difficulty: 'Advanced',
      price: 399,
      rating: 4.9,
      students: 15234
    },
    {
      id: 3,
      categoryId: 1,
      name: 'Cisco CCNA',
      description: 'Complete CCNA exam preparation covering networking fundamentals and Cisco technologies',
      duration: '120 mins',
      questionCount: 100,
      difficulty: 'Intermediate',
      price: 349,
      rating: 4.7,
      students: 9876
    }
    // Add more exams for other categories...
  ];

  getCategories(): Observable<any[]> {
    return of(this.categories);
  }

  getExamsByCategory(categoryId: number): Observable<any[]> {
    return of(this.exams.filter(exam => exam.categoryId === categoryId));
  }

  getCategory(id: number): Observable<any | undefined> {
    return of(this.categories.find(cat => cat.id === id));
  }
  
}