import { ExamWizardComponent } from './exam-wizard/exam-wizard.component';
import { ExamsComponent } from './exams.component';
import { ExamListComponent } from './exam-list/exam-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: ExamsComponent

  },
  { path: 'exams/:id', component: ExamListComponent },
  {
    path: 'start',
    component: ExamWizardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamsRoutingModule {


 }
