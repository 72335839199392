import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private tokenKey = 'auth_token';
  private authToken: string | null = null;
  private userName: string = null;

  constructor(private http: HttpClient, private router: Router) {
    // Initialize authToken from localStorage
    this.authToken = localStorage.getItem(this.tokenKey);
  }

  signUp(formData): Observable<any> {
    return this.http.post<any>(environment.PROXY_URL + 'auth/signup', formData, {})
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  signin(authData): Observable<any> {
    return this.http.post(environment.PROXY_URL + 'auth/signin', authData, {})
      .pipe(
        tap((response: any) => {
          if (response && response.token) {
            this.setAuthToken(response.token);
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  logout(): void {
    this.authToken = null;
    this.userName = null;
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  isLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  setAuthToken(token: string): void {
    this.authToken = token;
    localStorage.setItem(this.tokenKey, token);
  }

  getAuthToken(): string | null {
    if (!this.authToken) {
      this.authToken = localStorage.getItem(this.tokenKey);
    }
    return this.authToken;
  }

  getAuthHeaders(): HttpHeaders {
    const token = this.getAuthToken();
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  getUsername(): string {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user || !user.firstName) {
      return "My Account";
    } else {
      return user.firstName;
    }
  }

  setUserName(userName: string) {
    this.userName = userName;
  }
}
