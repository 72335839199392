import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamsRoutingModule } from './exams-routing.module';
import { ExamsComponent } from './exams.component';
import { ExamWizardComponent } from './exam-wizard/exam-wizard.component';
import { CountdownComponent } from '../common/countdown/countdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { CelebrationComponent } from '../common/celebration/celebration.component';
import { ExamCategoryComponent } from './exam-category/exam-category.component';
import { ExamListComponent } from './exam-list/exam-list.component';



@NgModule({
  declarations: [
    ExamsComponent,
    ExamWizardComponent,
    CountdownComponent,
    CelebrationComponent,
    ExamCategoryComponent,
    ExamListComponent
    ],
  imports: [
    CommonModule,
    ExamsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownModule
  ]
})
export class ExamsModule { }
