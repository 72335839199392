import { Component, OnInit, ViewChild} from '@angular/core';
import { TestsService } from 'src/app/services/tests.service';
import { ExamService } from 'src/app/services/exam.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-exam-wizard',
  templateUrl: './exam-wizard.component.html',
  styleUrls: ['./exam-wizard.component.scss']
})
export class ExamWizardComponent implements OnInit {

  minCounter = null;
  secCounter = null;
  question = {};
  answers = [];
  rightPanelClass = "col-md-6 tab-100 right-panel-flex";
  currentIndex = 0;
  total = 0;
  leftSidePresent = true;
  rightSidePresent = true;
  selectedOption;
  duration = 0;
  isNewTest = false;
  exam: any;
  examBlocks = [];
  blockQuestions = [];
  textAnswer: string = '';
  timerReady = false;
  blockIndex = 0;
  timeRemaining = null;

  loading: boolean = true;
  error: string | null = null;

  startTime = 300; // 5 minutes in seconds
  endTime = 0; // Count down to zero

  showCelebration = false;
  isExamFinished = false;

  totalMinutes = 0.0;
  examMinutesSpent: number = 0;
  totalScore = 0;
  totalQuestions = 0;
  rightQuestionsCounter = 0;

  //config: CountdownConfig = { leftTime: 0, format: 'mm:ss' };
  //@ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  config: CountdownConfig = { leftTime: 99999999, format: 'mm:ss', demand: true,  notify: 0 };
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  private currentBlockId: string;
  private destroy$ = new Subject<void>();
  isInitialEvent = true; 



  constructor(private service: TestsService, 
              private examService: ExamService, 
              private route: ActivatedRoute,
              private router: Router){}

  ngOnInit() {
   this.examMinutesSpent = 0;
   this.route.queryParams.subscribe(params => {
      const id = params['id'];
      console.log("params", params);
      if (id) {
        this.loadExam(id);
      } else {
        this.error = 'No exam ID provided';
        this.loading = false;
      }
    });

      if(this.isNewTest) {
        let i = 0;
        this.blockQuestions.forEach(question => {
          i++;
          question['options'].forEach(option => option['checked'] = false);
          question['options'].forEach(option => option['isCorrect'] = true);
        });
        this.isNewTest = false;
      }
  }

  loadExam(id: string): void {
    this.examService.getExamById(id).subscribe(
      exam => {
        this.exam = exam;
        this.loading = false;
        console.log("exam", exam);
        this.startExam();
      },
      error => {
        console.error('Error fetching exam:', error);
        this.error = 'Failed to load exam. Please try again.';
        this.loading = false;
      }
    );
  }

  startExam() {
    if(this.exam) {
      this.examBlocks = this.exam['exam_blocks'];
      console.log("block size ", this.examBlocks.length);
    }

    if(this.examBlocks && this.examBlocks.length) {
      this.examService.registerUserExam(this.exam);
      this.startExamBlock(this.examBlocks[0]);
    }

  }

  startExamBlock(examBlock: any) {
    // Stop any existing countdown
    if (this.countdown) {
      this.countdown.stop();
    }

    // Prevent duplicate starts for same block
    if (this.currentBlockId === examBlock['id']) {
      return;
    }
    this.currentBlockId = examBlock['id'];

    this.examService.getExamBlockById(examBlock['id']).pipe(
      takeUntil(this.destroy$) 
    ).subscribe(
      block => {
        console.log("examBlock: ", block);
        if(block['hasTimer']) {
          this.setDuration(block);
          console.log("has timer")
        } else {
          this.timerReady = false;
          console.log("doesn't have timer")
        }

        this.blockQuestions = block['questions'];
        this.total = this.blockQuestions.length;
        if(this.blockQuestions.length > 0) {
          this.currentIndex = 0;
          this.question = this.blockQuestions[this.currentIndex];
          this.leftSidePresent = this.question['leftPanel'] && this.question['leftPanel'].length > 0 ? true : false;
          this.rightSidePresent = this.question['rightPanel'] && this.question['rightPanel'].length > 0 ? true : false;
        } else {
          console.log("total is less then 1");
          console.log("tital", this.total);
        }
      },
      error => {
        console.error('Error fetching exam block:', error);
        this.error = 'Failed to load exam block. Please try again.';
        this.loading = false;
      }
    );
  }

  setDuration(block) {
    if (this.countdown) {
      this.countdown.stop();
    }
    const minutes = block['minutes'] || 0;
    const seconds = block['seconds'] || 0;
    console.log("setDuration, minutes: ", minutes);
    console.log("setDuration seconds: ", seconds);
    this.duration = (minutes * 60) + seconds;
    console.log("duration: ", this.duration);

    if (this.duration > 0) {
      this.timeRemaining = this.duration;
      this.minCounter = minutes;
      this.secCounter = seconds;
      this.timerReady = true;
      this.setTimer();
    } else {
      this.timerReady = false;
    }
  }
  
  prev() {
    this.answers[this.currentIndex] = this.question['options'];
    if(this.currentIndex > 0) {
      this.currentIndex--;
      this.question = this.blockQuestions[this.currentIndex];
      this.question['textAnswer'] = this.question['textAnswer'] ? this.question['textAnswer'] : "";
    }
  }

  next() {
    this.answers[this.currentIndex] = this.question['options'];
    if(this.currentIndex < this.total - 1) {
      this.currentIndex++;
      this.question = this.blockQuestions[this.currentIndex];
      this.question['textAnswer'] = this.question['textAnswer'] ? this.question['textAnswer'] : "";

    }
  }

  optionChecked(selectedOption) {
    console.log("option", selectedOption);
    if (this.question['singleCorrectOptionAnswer']) {
      this.question['options'].forEach(option => option.checked = false);
      selectedOption.checked = true;
    } else {
      selectedOption.checked = !selectedOption.checked;
    }
    this.answers[this.currentIndex] = this.question['options'];
  }

  getElapsedTime(): number {
    if (this.countdown?.left === undefined) {
        return 0;
    }

    const currentTime = Number(this.countdown.left) / 1000; 
    const duration = Number(this.duration);
    let timeSpent = (duration - currentTime) / 60.0;
    return isNaN(timeSpent) || !isFinite(timeSpent) ? 0 : timeSpent;
  }

  sendFinishedUserExamBlock(blockAnswers){
    this.examService.registerUserExamBlock(blockAnswers).subscribe({
      next: (response) => {
        console.log('UserExamBlock Saved Success:', response);
        if(response && response.results) {
           let totalCorrectAnswers = 0;
           let totalQuestions = 0;
           let totalScore = 0;
          for(let result of response.results) {
            totalCorrectAnswers += result['correctAnswers'];
            totalQuestions += result['totalQuestions'];
            totalScore += result['score'];
          }

          this.totalQuestions = totalQuestions;
          this.totalScore = totalScore;
          this.rightQuestionsCounter = totalCorrectAnswers;
        }
      },
      error: (error) => {
        console.error('UserExamBlock Error:', error);
      }
    });
  }

  submit() {
   // this.celebrate();
    console.log("submit, answers:", this.answers);
    console.log("submit, questions after submit:", this.blockQuestions);
    if(this.isExamFinished) {
      return;
    }
    console.log("submit after not last exam");
    this.isExamFinished = this.examBlocks.length - 1 == this.blockIndex;
    
    let blockTimeSpent = this.getElapsedTime();
    console.log("blockTimespent", blockTimeSpent);
    this.totalMinutes += 5;
    this.examMinutesSpent = this.examMinutesSpent + blockTimeSpent;
    console.log("totalMinutes", this.totalMinutes);
    console.log("examMinutesSpent", this.examMinutesSpent);

    if(!this.exam) {
      console.log("wrong submit, until exam is retrieved");
      return;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    if(!user) {
      console.log("no user, return");
      return;
    }
    const blockAnswers = {
      examId:  this.exam['_id'],
      blockId: this.examBlocks[this.blockIndex]['id'],
      userId: user['id'],
      answers: this.answers,
      isLast: this.isExamFinished
    }
    console.log("Submit blockAnswers", blockAnswers);

    if(this.blockQuestions.length > 0) {
      this.sendFinishedUserExamBlock(blockAnswers);
    }


    this.isNewTest = true;
    this.timerReady = false;

    if(this.blockIndex < this.examBlocks.length - 1) {
      console.log("second block after submit")
      this.blockIndex++;
      this.startExamBlock(this.examBlocks[this.blockIndex]);
    } else {
      //finish exam
      console.log("finish the exam");

      this.question = {};
      this.leftSidePresent = false;
      this.rightSidePresent = false;
      this.celebrate();
    }
  }

  viewDetailedResults() {
    console.log("viewDetailedResults");
    this.router.navigate(['my/exams/results'], {
      queryParams: {
        id: this.exam._id
      }
    });  
  }

  onCountdownComplete($event) {
    if (this.isInitialEvent) {
      this.isInitialEvent = false;
      return;
    }

    if($event.action == 'done') {
      console.log("finished, event.action == 'done', isInitialEvent", this.isInitialEvent);
     // this.countdown.restart();
      this.timerReady = false;
      this.submit();
    }
  }

  setTimer() {
    console.log("setTimer called");
    if (this.countdown) {
      this.countdown.stop();
    }
    if (this.duration > 0) {
      this.config = { ...this.config, leftTime: this.duration, format: this.duration > 3600 ?'hh:mm:ss' : 'mm:ss',  notify: 0, demand: true};
    }

    setTimeout(() => {
      if (this.countdown) {
        this.isInitialEvent = false; 
        this.countdown.begin();
      }
    }, 0);
  }
 
  celebrate() {
    this.showCelebration = true;
    setTimeout(() => {
      this.showCelebration = false;
    }, 2000);
  }

  ngOnDestroy() {
    if (this.countdown) {
      this.countdown.stop();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

}
