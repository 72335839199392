<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" style="z-index: 999; cursor: pointer;" routerLink="/"><span>Exam</span>Gym</a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar" style="background-color: #1C3169;"></span>
                <span class="middle-bar" style="background-color: #1C3169;"></span>
                <span class="bottom-bar" style="background-color: #1C3169;"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="exams">Exams</span></li>
                <li class="nav-item" *ngIf="!isLoggedIn()"><a class="nav-link" routerLink="auth/login">Sign In</a></li>
                <li class="nav-item" *ngIf="!isLoggedIn()"><a class="nav-link" routerLink="auth/register">Sign Up</a></li>
                <li class="nav-item dropdown" *ngIf="isLoggedIn()" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="userDropdown" role="button" ngbDropdownToggle>
                        {{ getUsername() }}
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="userDropdown">
                        <a class="dropdown-item" routerLink="/builder">Create Exam</a>
                        <a class="dropdown-item" routerLink="/my/exams">My Exams</a>
                        <a class="dropdown-item" routerLink="/profile">My Profile</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </div>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                 <li class="nav-item"><a class="nav-link" routerLink="/">Home</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="exams">Exams</a></li>
                <li class="nav-item" *ngIf="!isLoggedIn()"><a class="nav-link" routerLink="auth/login">Sign In</a></li>
                <li class="nav-item" *ngIf="!isLoggedIn()"><a class="nav-link" routerLink="auth/register">Sign Up</a></li>
                <li class="nav-item dropdown" *ngIf="isLoggedIn()" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="userDropdown" role="button" ngbDropdownToggle>
                        {{ getUsername() }}
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="userDropdown">
                        <a class="dropdown-item" routerLink="/my/exams">My Exams</a>
                        <a class="dropdown-item" routerLink="/profile">My Profile</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>