// celebration.component.ts
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';

interface ConfettiPiece {
  id: number;
  x: number;
  color: string;
  delay: number;
  size: number;
  shape: number;
}

@Component({
  selector: 'app-celebration',
  templateUrl: './celebration.component.html',
  styleUrls: ['./celebration.component.scss'],
  animations: [
    trigger('bounce', [
      state('up', style({
        transform: 'translateY(0)'
      })),
      state('down', style({
        transform: 'translateY(20px)'
      })),
      transition('up <=> down', [
        animate('0.5s cubic-bezier(0.4, 0, 0.2, 1)')
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.5s 0.3s ease-out', 
          style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class CelebrationComponent implements OnInit, OnDestroy {
  @Input() show: boolean = false;
  @Input() message: string = 'Great job!';
  @Input() soundEnabled: boolean = true;
  
  bounceState: 'up' | 'down' = 'up';
  
 

  confetti: ConfettiPiece[] = Array(150).fill(null).map((_, i) => ({
    id: i,
    x: Math.random() * 100, // random position across width
    color: this.getRandomColor(),
    delay: Math.random() * 3, // random delay up to 3s
    size: Math.random() * 10 + 8, // size between 8-18px
    shape: Math.floor(Math.random() * 4) // 4 different shapes
  }));

  getRandomColor(): string {
    const colors = [
      '#FF4081', // Pink
      '#3F51B5', // Indigo
      '#FFC107', // Amber
      '#4CAF50', // Green
      '#9C27B0', // Purple
      '#FF9800', // Orange
      '#2196F3', // Blue
      '#E91E63', // Deep Pink
      '#00BCD4', // Cyan
      '#FFEB3B', // Yellow
      '#F44336', // Red
      '#673AB7', // Deep Purple
      '#CDDC39', // Lime
      '#009688', // Teal
      '#FFFF00'  // Bright Yellow
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  }


  private bounceInterval?: number;
  private audioContext?: AudioContext;

  ngOnInit() {
    this.initializeAudio();
    this.startBounceAnimation();
  }

  ngOnDestroy() {
    if (this.bounceInterval) {
      clearInterval(this.bounceInterval);
    }
  }

  private initializeAudio() {
    this.audioContext = new AudioContext();
  }

  private startBounceAnimation() {
    this.bounceInterval = window.setInterval(() => {
      this.bounceState = this.bounceState === 'up' ? 'down' : 'up';
    }, 500);
  }

  // Add this method to your celebration.component.ts
// Replace the existing playWinningSound method with this one:

private playWinningSound() {
  if (!this.audioContext) return;
  
  const now = this.audioContext.currentTime;
  
  // Create a rich synth sound
  const createSynth = (type: OscillatorType, frequency: number, startTime: number, duration: number, gainValue: number) => {
      const oscillator = this.audioContext!.createOscillator();
      const gainNode = this.audioContext!.createGain();
      const filterNode = this.audioContext!.createBiquadFilter();
      
      // Connect nodes
      oscillator.connect(filterNode);
      filterNode.connect(gainNode);
      gainNode.connect(this.audioContext!.destination);
      
      // Set oscillator properties
      oscillator.type = type;
      oscillator.frequency.setValueAtTime(frequency, startTime);
      
      // Add slight pitch bend up for sparkle
      oscillator.frequency.linearRampToValueAtTime(frequency * 1.02, startTime + 0.1);
      
      // Set filter properties for warmth
      filterNode.type = 'lowpass';
      filterNode.frequency.setValueAtTime(5000, startTime);
      filterNode.Q.setValueAtTime(1, startTime);
      
      // Shape the sound envelope (ADSR)
      gainNode.gain.setValueAtTime(0, startTime);
      gainNode.gain.linearRampToValueAtTime(gainValue, startTime + 0.05); // Attack
      gainNode.gain.linearRampToValueAtTime(gainValue * 0.6, startTime + 0.2); // Decay
      gainNode.gain.linearRampToValueAtTime(gainValue * 0.5, startTime + duration - 0.1); // Sustain
      gainNode.gain.linearRampToValueAtTime(0, startTime + duration); // Release
      
      oscillator.start(startTime);
      oscillator.stop(startTime + duration);
  };

  // Create a reverb effect
  const createReverb = async () => {
      const convolver = this.audioContext!.createConvolver();
      const reverbLength = 2;
      const sampleRate = this.audioContext!.sampleRate;
      const impulse = this.audioContext!.createBuffer(2, reverbLength * sampleRate, sampleRate);
      
      for (let channel = 0; channel < impulse.numberOfChannels; channel++) {
          const impulseData = impulse.getChannelData(channel);
          for (let i = 0; i < impulseData.length; i++) {
              impulseData[i] = (Math.random() * 2 - 1) * Math.pow(1 - i / impulseData.length, 2);
          }
      }
      
      convolver.buffer = impulse;
      return convolver;
  };

  // Main achievement sound composition
  const playAchievement = async () => {
      const reverb = await createReverb();
      reverb.connect(this.audioContext!.destination);

      // Bright initial chord
      createSynth('sine', 440, now, 0.6, 0.2);  // A4
      createSynth('sine', 554.37, now + 0.02, 0.6, 0.15);  // C#5
      createSynth('sine', 659.25, now + 0.04, 0.6, 0.15);  // E5
      
      // Sparkling arpeggio
      createSynth('sine', 880, now + 0.1, 0.3, 0.1);  // A5
      createSynth('sine', 1108.73, now + 0.15, 0.3, 0.08);  // C#6
      createSynth('sine', 1318.51, now + 0.2, 0.3, 0.08);  // E6
      
      // Rich pad sound for body
      createSynth('sine', 220, now + 0.1, 0.8, 0.1);  // A3
      createSynth('triangle', 277.18, now + 0.1, 0.8, 0.08);  // C#4
      createSynth('triangle', 329.63, now + 0.1, 0.8, 0.08);  // E4

      // Shimmering high frequencies
      for (let i = 0; i < 3; i++) {
          createSynth('sine', 1760 + (i * 100), now + 0.3 + (i * 0.05), 0.2, 0.02);
      }
  };

  playAchievement();
}

  onShow() {
    if (this.soundEnabled) {
      this.playWinningSound();
    }
  }
}