import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ExamService } from 'src/app/services/exam.service';


@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit, OnChanges {
  @Input() categoryId;
  category?: any;
  exams: any[] = [];
  filteredExams: any[] = [];
  searchTerm: string = '';
  selectedDifficulty: string = '';
  showHeader = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private examService: ExamService
  ) { }

  ngOnInit(): void {
    console.log("shemovida exam listshi", this.categoryId);

    this.route.paramMap.pipe(
      switchMap(params => {
        this.categoryId = Number(params.get('id'));
        this.showHeader = this.categoryId ? true : false;
        this.examService.getCategory(this.categoryId).subscribe(category => {
          this.category = category;
        });

        return this.examService.getExamsByCategory(this.categoryId);
      })
    ).subscribe(exams => {
      this.exams = exams;
      this.filteredExams = exams;
    });
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Handle Input-based changes
    if (changes['categoryId'] && changes['categoryId'].currentValue) {
      this.loadCategoryAndExams(changes['categoryId'].currentValue).subscribe();
    }
  }

  private loadCategoryAndExams(id: number) {
    // Get both category and exams
    this.examService.getCategory(id).subscribe(category => {
      this.category = category;
    });
    return this.examService.getExamsByCategory(id).pipe(
      switchMap(exams => {
        this.exams = exams;
        this.filteredExams = exams;
        return this.examService.getCategory(id);
      })
    );
  }

  filterExams(): void {
    let filtered = this.exams;
    
    if (this.searchTerm.trim()) {
      const search = this.searchTerm.toLowerCase();
      filtered = filtered.filter(exam =>
        exam.name.toLowerCase().includes(search) ||
        exam.description.toLowerCase().includes(search)
      );
    }
    
    if (this.selectedDifficulty) {
      filtered = filtered.filter(exam =>
        exam.difficulty === this.selectedDifficulty
      );
    }
    
    this.filteredExams = filtered;
  }

  getDifficultyClass(difficulty: string): string {
    switch (difficulty) {
      case 'Beginner': return 'bg-success';
      case 'Intermediate': return 'bg-warning';
      case 'Advanced': return 'bg-danger';
      default: return 'bg-secondary';
    }
  }

  navigateToExams() {
    console.log("navigateToExams clicked");
    //my/exams/results?id=674f1279ec09f2780fea1146
    let id = '674f1279ec09f2780fea1146';
    this.router.navigate(['my/exams/results'], {
      queryParams: {
        id: id
      }
    });
  }
}