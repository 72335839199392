<div class="main overflow-hidden" style="display: flex; align-items: center; justify-content: center;">
    <div [hidden]="!showCelebration">
        <app-celebration
        [show]="showCelebration"
        [soundEnabled]="true"
        message="Amazing job!">
       </app-celebration>
    </div>
    <!--div [hidden]="!timerReady" class="countdown" style="display: flex; justify-content: center; align-items: center;">
        <countdown #cd [config]="config" (event)="onCountdownComplete($event)" style="font-size: 22px;"/>
         <countdown 
            #cd 
            [config]="config" 
            (event)="onCountdownComplete($event)" 
            style="font-size: 22px;"
            *ngIf="timerReady && duration > 0"
        />
        
    </div-->
    <div [hidden]="!timerReady" class="countdown" style="display: flex; justify-content: center; align-items: center;">
        <countdown 
        [hidden]="isInitialEvent"
        #cd 
        [config]="config" 
        (event)="onCountdownComplete($event)" 
        style="font-size: 22px;"
        *ngIf="timerReady && duration > 0"
    />
    </div>
    <div class="row">
        <div [hidden]="!leftSidePresent" class="tab-100 order-tab tab-none"
        [ngClass]="{'col-md-6': rightSidePresent, 'col-md-8 offset-md-2': !rightSidePresent}">
            <div class="side" style="display: block; margin-top: 150px; margin-left: 30px; margin-right: 30px;">
              
                <div *ngFor="let question of question['leftPanel']" class="container">
                    <div *ngIf="question['type'] == 'image'" style="margin-left: 30px; margin-right: 50px;">
                        <img style="display: block; margin-left: auto; margin-right: auto;" [src]="question.content">
                    </div>
                    <div *ngIf="question.type == 'text'" class="container" style="margin-right: 50px;">
                        <p style="font-size: 16px; font-weight: 500; color: #1C3169; margin: 30px">
                            {{ question.content }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!--div class="splitter-line"> &nbsp; </div-->

        <div [hidden]="!rightSidePresent" class="tab-100 right-panel-flex" [ngClass]="{'col-6': leftSidePresent, 'col-12 offset-md-2': !leftSidePresent}">
            <div class="container" style="margin-left: 30px;">
                <div class="container" style="margin-top: 110px; margin-bottom: 30px; margin-right: 50px;">
                    <div class="py-2 h5" style="margin-right: 50px;">
                        <div *ngFor="let question of question['rightPanel']" class="container">
                            <div *ngIf="question['type'] == 'image'" style="margin-left: 30px; margin-right: 50px;">
                                <img style="display: block; margin-left: auto; margin-right: auto;" [src]="question.content">
                            </div>
                            <div *ngIf="question.type == 'text'" class="container" style="margin-right: 50px;">
                                <p style="font-size: 16px; font-weight: 500; color: #1C3169; margin: 30px">
                                    {{ question.content }}
                                </p>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="total > 0" class="row">
        <div [hidden]="!leftSidePresent || !rightSidePresent" class="col-6 tab-100 right-panel-flex">
        </div>
       
        <div class="tab-100 right-panel-flex" [ngClass]="{'col-6': leftSidePresent && rightSidePresent, 'col-12 offset-md-2': !leftSidePresent || !rightSidePresent}">
            <div class="container" style="margin-left: 30px;">
                <div class="question ml-sm-5 pl-sm-5 pt-2">           
                    <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="options">
                        <ng-container *ngIf="question['answerFormat'] == 'chooseFromOptions' ">
                            <div *ngFor="let option of question['options']" class="container">
                                <label *ngIf="option.type == 'text'" class="options" style="display: flex; margin: 20px;">
                                    {{option.content}}
                                    <ng-container *ngIf="question['singleCorrectOptionAnswer']; else multipleOptionAnswer">
                                        <input type="radio" name="radio" [checked]="option.checked" (change)="optionChecked(option)">
                                        <span class="checkmark radio"></span>
                                    </ng-container>
                                    <ng-template #multipleOptionAnswer>
                                        <input type="checkbox" [checked]="option.checked" (change)="optionChecked(option)">
                                        <span class="checkmark checkbox"></span>
                                    </ng-template>
                                </label>
                                <label *ngIf="option.type == 'image'" class="options" style="display: flex; margin: 20px;">
                                    <ng-container *ngIf="question['singleCorrectOptionAnswer']; else multipleOptionAnswerImage">
                                        <input type="radio" name="radio" [checked]="option.checked" (change)="optionChecked(option)">
                                        <span class="checkmark radio"></span>
                                    </ng-container>
                                    <ng-template #multipleOptionAnswerImage>
                                        <input type="checkbox" [checked]="option.checked" (change)="optionChecked(option)">
                                        <span class="checkmark checkbox"></span>
                                    </ng-template>
                                    <img style="display: block; margin-left: auto; margin-right: auto;" [src]="option.content">
                                </label>
                            </div>  
                        </ng-container>
                        <ng-container *ngIf="question['answerFormat'] == 'shortText' ">
                            <input type="text" 
                                   [(ngModel)]="question['textAnswer']" 
                                   class="form-control" 
                                   placeholder="Enter your answer"
                                   style="height: 40px; width: 100%;">
                        </ng-container> 
                        <ng-container *ngIf="question['answerFormat'] == 'longText' ">
                            <textarea [(ngModel)]="question['textAnswer']" 
                                      class="form-control" 
                                      placeholder="Enter your answer"
                                      rows="4"
                                      style="width: 100%; resize: vertical;">
                            </textarea>
                        </ng-container>  
                    </div>
                </div>
            </div>   
        </div>
   
        <ng-container *ngIf="total > 0 && !isExamFinished" >
            <div class="container" style="margin-bottom: 30px; margin-top: 30px; display: flex; justify-content: center;">
                <div class="container" style="display: flex; justify-content: center; align-items: center;">
                    <span  *ngIf="currentIndex > 0" class="tab-button" id="step2btn" (click)="prev()"><i class="fa-solid fa-arrow-left" style="margin-right: 20px;"></i>Prev Question</span>
                    <span *ngIf="currentIndex < total - 1" class="tab-button" type="button" id="step1btn" (click)="next()">Next Question<i class="fa-solid fa-arrow-right" style="margin-left: 20px;justify-content: end;"></i></span>
                    <span *ngIf="currentIndex > total - 2" class="app-button" style="width: 140px;" type="button" id="step1btn" (click)="submit()">Submit</span>
                </div>             
            </div>
        </ng-container>  
      </div>

      <ng-container *ngIf="total == 0">
        <div class="break-container">
            <h1 class="break-title">Break Time!</h1>
            <div class="timer">       
                 <countdown #cd [config]="config" (event)="onCountdownComplete($event)" style="font-size: 22px;"/>
            </div>
            <div class="progress-bar">
                <div class="progress"></div>
            </div>
            <p class="break-message">Take a moment to relax and recharge before continuing your exam.</p>
            <ul class="advice-list">
                <li>Stand up and stretch</li>
                <li>Take a few deep breaths</li>
                <li>Get some water</li>
                <li>Rest your eyes from the screen</li>
            </ul>
         </div>
      </ng-container>
     

     <div *ngIf="isExamFinished" class="completion-container">
        <div class="success-icon">
            <i class="fas fa-check-circle"></i>
        </div>
        <h1 class="completion-title">Congratulations!</h1>
        <p class="completion-message">You've completed your exam</p>
        
        <div class="score-box">
            <div class="score-label">Your Score</div>
            <div class="score-value">{{totalScore}}</div>
        </div>
        
        <div class="stats-grid">
            <div class="stat-item">
                <div class="stat-value">{{rightQuestionsCounter + '/' + totalQuestions}}</div>
                <div class="stat-label">Questions Correct</div>
            </div>
        </div>

        <button class="view-results-button" (click)="viewDetailedResults()">
            View Detailed Results
            <i class="fas fa-arrow-right"></i>
        </button>
        
        <button class="return-home-button">
            Return to Dashboard
        </button>
    </div>
</div>