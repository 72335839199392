<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How It Works</h2>
            <p>Ready to make your scores soar?</p>
            <p>Start practicing with us today!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <!--div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div-->
                    </div>
                    <div class="services-content">
                        <h3>Practice</h3>
                        <p>Practice SAT tests to train yourself and watch your scores soar in record time.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg" alt="services-img">
                        <!--div class="icon">
                            <i class="fab fa-linode"></i>
                        </div-->
                    </div>
                    <div class="services-content">
                        <h3>Explanations</h3>
                        <p>Transform mistakes into learning opportunities with our instant, in-depth explanations.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg" alt="services-img">
                        <!--div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div-->
                    </div>
                    <div class="services-content">
                        <h3>SAT Cracking Sessions</h3>
                        <p>Join live "SAT Cracking Sessions" and gain insights from top scorers!</p>
                    </div>
                </div>
            </div>
            <!--div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Analytics</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img7.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Responsive Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img8.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Photography</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img9.jpg" alt="services-img">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Support</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</div>