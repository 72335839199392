<!--div id="exams" class="services-area pt-100">
    <div class="container mt-4">
        <div class="section-title">
            <h3 class="exams-title">Start practicing with us today!</h3>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        <div class="col" *ngFor="let exam of exams">
            <div class="card h-100 shadow-sm">
            <div class="card-header text-white">
                <h5 class="card-title mb-0">{{ exam.title }}</h5>
            </div>
            <div class="card-body" style="padding-bottom: 0px;" (click)="toExam(exam)">
                <h6 class="card-subtitle mb-2 text-muted">{{ 'Exam: ' + exam.category }}</h6>
                <p class="card-text"><strong>ID:</strong> {{ exam._id }}</p>
                <div style="display: flex; justify-content: center;">
                    <span class="app-button" [class.locked]="!isAvailable(exam)" (click)="toExam(exam)">
                        <i *ngIf="!isAvailable(exam)" style="border: 2px solid;" class="fas fa-lock"></i>
                        Start
                    </span>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="text-center mt-4">
        <button class="app-button" (click)="loadMore()" *ngIf="hasMore" [disabled]="loading" style="margin-top: 50px;">
            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" *ngIf="loading"></span>
            {{ loading ? 'Loading...' : 'Load More' }}
        </button>
        </div>
    </div>
</div-->
<div style="padding-top: 60px;">
    <app-exam-categories></app-exam-categories>
</div>
