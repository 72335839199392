<div *ngIf="showHeader" class="hero bg-primary text-white pt-100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item"><a href="#" class="text-white" routerLink="/">Categories</a></li>
              <li class="breadcrumb-item active text-white" aria-current="page">{{category?.name}}</li>
            </ol>
          </nav>
          <h2 class="display-6 fw-bold mt-3">{{category?.name}}</h2>
          <p class="lead" style="color: white;">{{category?.description}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-5">
    <!-- Filters -->
    <div class="row mb-4">
      <div class="col-md-6" style="margin-bottom: 15px;">
        <div class="input-group">
          <input type="text" class="form-control" 
                 placeholder="Search exams..."
                 [(ngModel)]="searchTerm"
                 (input)="filterExams()">
          <button class="btn btn-primary">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6" style="margin-bottom: 15px;">
        <select class="form-select" [(ngModel)]="selectedDifficulty" (change)="filterExams()" style="padding: 10px;">
          <option value="">All Difficulties</option>
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
      </div>
    </div>

    <!-- Exam List -->
    <div class="row g-4">
      <div class="col-md-6 col-lg-4" *ngFor="let exam of filteredExams">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body p-4">
            <div class="d-flex justify-content-between mb-3">
              <span [class]="'badge ' + getDifficultyClass(exam.difficulty)">
                {{exam.difficulty}}
              </span>
              <span class="badge bg-light text-dark">
                <i class="fas fa-clock me-1"></i> {{exam.duration}}
              </span>
            </div>
            
            <h3 class="card-title h5 fw-bold mb-3">{{exam.name}}</h3>
            <p class="card-text text-muted small mb-4" style="color: white;">{{exam.description}}</p>
            
            <!--div class="mb-3">
              <div class="d-flex align-items-center text-warning mb-2">
                <i class="fas fa-star"></i>
                <span class="ms-2">{{exam.rating}}/5.0</span>
                <span class="text-muted ms-2">({{exam.students}} students)</span>
              </div>
              <div class="d-flex align-items-center">
                <i class="fas fa-question-circle text-primary"></i>
                <span class="ms-2">{{exam.questionCount}} questions</span>
              </div>
            </div-->
            
            <div class="d-flex justify-content-between align-items-center">
              <span class="h5 mb-0">${{exam.price}}</span>
              <button class="btn btn-primary" (click)="navigateToExams()">Start Prep</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>