<!-- leaderboard.component.html -->
<div class="leaderboard-container">
    <div class="leaderboard-section">
      <h2>Top Performers This Week</h2>
      <div class="leaderboard">
        <div class="leaderboard-header">
          <span class="rank">Rank</span>
          <span class="name">Name</span>
          <span class="score">Score</span>
        </div>
        <div class="leaderboard-entries">
          <div *ngFor="let entry of weeklyLeaders" 
               class="leaderboard-entry"
               [class.top-three]="entry.rank <= 3">
            <span class="rank">{{entry.rank}}</span>
            <span class="name">{{entry.name}}</span>
            <span class="score">{{entry.score}}</span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="leaderboard-section">
      <h2>Top Performers This Month</h2>
      <div class="leaderboard">
        <div class="leaderboard-header">
          <span class="rank">Rank</span>
          <span class="name">Name</span>
          <span class="score">Score</span>
        </div>
        <div class="leaderboard-entries">
          <div *ngFor="let entry of monthlyLeaders" 
               class="leaderboard-entry"
               [class.top-three]="entry.rank <= 3">
            <span class="rank">{{entry.rank}}</span>
            <span class="name">{{entry.name}}</span>
            <span class="score">{{entry.score}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>