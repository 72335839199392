<div class="hero bg-primary text-white py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h3 class="display-6 fw-bold mb-3">Explore Exam Categories</h3>
          <p class="lead" style='color: white;'>Find the perfect preparation materials for your next certification or exam</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content -->
  <div class="container py-5">
    <!-- Search Bar -->
    <!--div class="row justify-content-center mb-5">
      <div class="col-lg-6">
        <div class="input-group">
          <input type="text" class="form-control form-control-lg" 
                 placeholder="Search for exam categories..."
                 [(ngModel)]="searchTerm"
                 (input)="filterCategories()">
          <button class="btn btn-primary">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div-->

    <!-- Categories Grid -->
    <div class="row g-4">
      <div class="col-md-6 col-lg-4" *ngFor="let category of filteredCategories">
        <div class="card h-100 border-0 shadow-sm cursor-pointer" (click)="navigateToExams(category.id)">
          <div class="card-body p-4">
            <div class="mb-3">
              <span [class]="'icon-circle ' + category.bgClass">
                <i [class]="category.iconClass"></i>
              </span>
            </div>
            
            <h3 class="card-title h4 fw-bold mb-3">{{category.name}}</h3>
            <p class="card-text text-muted mb-4">{{category.description}}</p>
            
            <div class="d-flex justify-content-between align-items-center">
              <span class="badge bg-primary rounded-pill" style='padding: 10px 15px;'>{{category.examCount}} Exams</span>
              <button class="btn btn-outline-primary">Explore</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedCategoryId" class="container mx-auto px-4 py-8">
    <app-exam-list [categoryId]="selectedCategoryId"></app-exam-list>
  </div>