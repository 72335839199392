import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';


interface Exam {
  _id: string;
  title: string;
  status: string;
  languages: string[];
  type: string;
  category: string;
  start_date: Date;
  end_date: Date;
}

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent {
     exams: Exam[] = [];
     page = 1;
     limit = 9;
     hasMore = true;
     loading = false;
   
     constructor(private examService: ExamService, private router: Router) {}
   
     ngOnInit(): void {
       this.loadExams();
     }
   
     loadExams(): void {
       console.log("loadExams");
       if (this.loading) return;
       this.loading = true;
       this.examService.getExams(this.page, this.limit).subscribe(
         (newExams: Exam[]) => {
           console.log("newExams", newExams);
           this.exams = [...this.exams, ...newExams];
           this.hasMore = newExams.length === this.limit;
           this.page++;
           this.loading = false;
         },
         error => {
           console.error('Error fetching exams:', error);
           this.loading = false;
         }
       );
     }
   
     loadMore(): void {
       this.loadExams();
     }

     toExam(exam) {
      if (!this.isAvailable(exam)) {
        return;
      }
    
      let user = JSON.parse(localStorage.getItem('user'));
      this.examService.getUserExam(user['id'], exam['_id']).subscribe({
        next: (userExam) => {
          console.log('UserExam exists:', userExam);
          this.router.navigate(['my/exams/results'], {
            queryParams: {
              id: exam['_id']
            }
          });
        },
        error: (error) => {
          if (error.status === 404) {
            console.log('No existing exam found, starting new exam');
            this.router.navigate(["/","exams", "start"], {
              queryParams: {
                id: exam._id
              }
            });
          } else {
            console.error('Unexpected error checking UserExam:', error);
          }
        }
      });
    }

    isAvailable(exam: any): boolean {
      try {
          // Get exam start date
          const user = JSON.parse(localStorage.getItem("user"));
          console.log("user");
          if(user['id'] && user['accessToken']) {
            return true;
          }
          console.log("not user", user);
          const examStartDate = new Date(exam.date);
          if(!examStartDate) {
            return false;
          }
          
          // Calculate exam end date (2 hours after start)
          const examEndDate = new Date(examStartDate.getTime() + (2 * 60 * 60 * 1000));
          
          // Get current time in PST/San Francisco
          const currentPSTTime = new Date().toLocaleString("en-US", {
              timeZone: "America/Los_Angeles"  // San Francisco timezone
          });
          const currentTime = new Date(currentPSTTime);
  
          // Debug logs
          console.log('Checking availability (PST/SF time):');
          console.log('Exam starts:', examStartDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
          console.log('Current time:', currentTime.toLocaleString());
          console.log('Exam ends:', examEndDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
  
          // Check if current PST time is within the exam window
          return currentTime >= examStartDate && currentTime <= examEndDate;
  
      } catch (error) {
          console.error('Error checking exam availability:', error);
          return false;
      }
  }

 
}

