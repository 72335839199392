import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {

  private countdownTime$ = new BehaviorSubject<string>('00:00');
  private initialDuration: number = 0;
  private startTime: number | null = null;
  private countdownComplete$ = new Subject<void>();

  startCountdown(durationInSeconds: number, startNewCountdown: boolean = false) {
    this.initialDuration = durationInSeconds;

    if (!this.startTime || startNewCountdown) {
      // Record the start time when the countdown first begins
      this.startTime = Date.now();
    }

    this.updateCountdown(); // Calculate the remaining time immediately

    const countdown$ = interval(1000).pipe(
      take(durationInSeconds)
    );

    countdown$.subscribe(() => {
      this.updateCountdown();
    });

    countdown$.subscribe({
      next: () => this.updateCountdown(),
      complete: () => this.countdownComplete$.next()  // Emit event when countdown completes
    });
  }

  private updateCountdown() {
    const now = Date.now();
    const elapsedTime = Math.floor((now - (this.startTime || now)) / 1000);
    const remainingTime = Math.max(this.initialDuration - elapsedTime, 0);

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    this.countdownTime$.next(this.formatTime(minutes, seconds));
  }

  getCountdownTime() {
    return this.countdownTime$.asObservable();
  }

  private formatTime(minutes: number, seconds: number): string {
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutesString}:${secondsString}`;
  }

  getCountdownComplete() {
    return this.countdownComplete$.asObservable();  // Observable for when countdown is finished
  }

}
