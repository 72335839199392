import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AccountService } from 'src/app/services/account.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    isNavbarCollapsed = true;
    classApplied = false;

    constructor(private viewportScroller: ViewportScroller, private accountService: AccountService) {}

    ngOnInit() { }

    public onClick(elementId: string): void { 
         this.viewportScroller.scrollToAnchor(elementId);
    }

    toggleClass() {
       this.classApplied = !this.classApplied;
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    logout() {
        this.accountService.logout();
    }

    isLoggedIn() {
        return this.accountService.isLoggedIn();
    }

    getUsername() {
        return this.accountService.getUsername();
    }
}