<!-- celebration.component.html -->
<div class="celebration-container" *ngIf="show" (animationstart)="onShow()">
  <!-- Confetti container -->
  <div class="confetti-container">
    <div *ngFor="let piece of confetti" 
         class="confetti"
         [ngClass]="'shape-' + piece.shape"
         [ngStyle]="{
           'left': piece.x + '%',
           'background-color': piece.color,
           'animation-delay': piece.delay + 's',
           'width': piece.size + 'px',
           'height': piece.size + 'px'
         }">
    </div>
  </div>
  
  <!-- Character -->
  <div class="character" [@bounce]="bounceState">
    <div class="character-body">
      <div class="face">
        <div class="eyes">
          <div class="eye"></div>
          <div class="eye"></div>
        </div>
        <div class="smile"></div>
      </div>
    </div>
  </div>
  
  <div class="message" [@fadeIn]>
    <h2>{{ message }}</h2>
  </div>
</div>