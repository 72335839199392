
/*import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CountdownService } from 'src/app/services/countdown.service'; 
import { Observable } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {
  @Input() duration: number = 300;  // Default to 5 minutes
  @Output() onComplete = new EventEmitter<void>();  

  countdown$: Observable<string> | undefined;

  constructor(private countdownService: CountdownService) {}

  ngOnInit(): void {
    this.countdownService.startCountdown(this.duration, true);
    this.countdown$ = this.countdownService.getCountdownTime();

    this.countdownService.getCountdownComplete().subscribe(() => {
      this.onCountdownComplete();
    });
  }

  onCountdownComplete() {
   this.onComplete.emit();
  }
}
*/

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { CountdownService } from 'src/app/services/countdown.service'; 
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() duration: number = 300;  // Default to 5 minutes
  @Output() onComplete = new EventEmitter<void>();  

  countdown$: Observable<string> | undefined;
  private completeSubscription: Subscription | undefined;

  constructor(private countdownService: CountdownService) {}

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['duration'] && !changes['duration'].firstChange) {
      this.startCountdown();
    }
  }

  ngOnDestroy(): void {
    this.cleanup();
  }

  private startCountdown(): void {
    this.cleanup(); // Clean up previous countdown if any
    
    this.countdownService.startCountdown(this.duration, true);
    this.countdown$ = this.countdownService.getCountdownTime();

    this.completeSubscription = this.countdownService.getCountdownComplete().subscribe(() => {
      this.onCountdownComplete();
    });
  }

  private cleanup(): void {
    if (this.completeSubscription) {
      this.completeSubscription.unsubscribe();
    }
  }

  onCountdownComplete(): void {
    this.onComplete.emit();
  }
}