<footer class="footer-area">
    <div class="container">
        <ul>
            <li><a href="https://www.facebook.com/people/ExamGym/61557756020578" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="https://www.linkedin.com/company/examgym" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="https://www.instagram.com/exam_gym/" target="_blank" class="fab fa-instagram"></a></li>
        </ul>
        <p>©ExamGym 2023 All rights reserved.</p>
    </div>
</footer>

<!--app-demo-sidebar></app-demo-sidebar-->
