import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';

interface ExamCategory {
  id: number;
  name: string;
  description: string;
  iconClass: string;
  examCount: number;
  bgClass: string;
}

@Component({
  selector: 'app-exam-categories',
  templateUrl: './exam-category.component.html',
  styleUrls: ['./exam-category.component.scss']
})
export class ExamCategoryComponent implements OnInit {
  categories: ExamCategory[] = [];
  filteredCategories: ExamCategory[] = [];
  searchTerm: string = '';
  selectedCategoryId;
 
  constructor(
    private examService: ExamService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.examService.getCategories().subscribe(categories => {
      this.categories = categories;
      this.filteredCategories = categories;
    });
  }

  filterCategories(): void {
    if (!this.searchTerm.trim()) {
      this.filteredCategories = this.categories;
      return;
    }
    
    const search = this.searchTerm.toLowerCase();
    this.filteredCategories = this.categories.filter(category =>
      category.name.toLowerCase().includes(search) ||
      category.description.toLowerCase().includes(search)
    );
  }

  navigateToExams(categoryId: number): void {
    this.router.navigate(['/exams', categoryId]);
    //this.selectedCategoryId = categoryId;
    console.log("selectedCategoryId", this.selectedCategoryId);
  }
}