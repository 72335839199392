<!--app-navbar></app-navbar>
<div class="main" id="main_content_id">
    <router-outlet></router-outlet>
</div>
   
<app-footer></app-footer-->


<div class="page-container">
    <app-navbar></app-navbar>
    <div class="content-wrap">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>
